<template>
  <component :is="playerData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="playerData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching player data
      </h4>
      <div class="alert-body">
        No user found with this player id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-players-list'}"
        >
          Player List
        </b-link>
        for other players.
      </div>
    </b-alert>
    <div class="mb-2">
      <feather-icon
            icon="ArrowLeftIcon"
            size="16"
            class="mr-0"
          />
          <span class="d-none d-sm-inline">
            <b-link
          class="alert-link"
          :to="{ name: 'apps-players-list'}"
        >
         Back to Player List
        </b-link>
          </span>
    </div>
    <div v-if="playerData!=undefined"
    class="mb-2">Player: {{ playerData.name }} - {{ playerData.msisdn }}</div>
    
    <b-tabs
      v-if="playerData"
      pills
    >

      <!-- Tab: Account -->
      <!-- <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Bets</span>
        </template>
        <bets-list
          :player-data="playerData"
          class="mt-2 pt-75"
        />
      </b-tab> -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Transactions</span>
        </template>
        <player-view-transactions-card
          :player-data="playerData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BTab, BTabs, BCard,
} from 'bootstrap-vue'
import playerStoreModule from '../playerStoreModule'
import PlayerViewTransactionsCard from './PlayerViewTransactionsCard.vue'
// import PlayerViewBetsCard from './PlayerViewBetsCard.vue'
// import BetsList from '../../bets/bets-list/BetsList.vue'


import router from '@/router'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTab,
    BTabs,
    BCard,

    // Local Components
    PlayerViewTransactionsCard,
    // PlayerViewBetsCard,
    // BetsList,

  },
  setup() {
    const playerData = ref(null)

    const PLAYER_APP_STORE_MODULE_NAME = 'players'

    // Register module
    if (!store.hasModule(PLAYER_APP_STORE_MODULE_NAME)) store.registerModule(PLAYER_APP_STORE_MODULE_NAME, playerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PLAYER_APP_STORE_MODULE_NAME)) store.unregisterModule(PLAYER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('players/fetchPlayer', { id: router.currentRoute.params.id })
      .then(response => {
        playerData.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          playerData.value = undefined
        }
      })

    return {
      playerData,
    }
  },
}
</script>

<style>

</style>
