import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function usePlayerTransactionsList(props) {
  // Use toast
  const playerData = props.playerData
  const toast = useToast()

  const refPlayerTransactionListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'type',
    },
    { 
      key: 'initial_balance',
      sortable: true,
      formatter: val => `KES ${Number(val).toLocaleString()}`
    },
    {
      key: 'subject',
    },
    {
      key: 'details',
    },
    {
      key: 'value',
      label: 'Amount',
      sortable: true,
    },
    { 
        key: 'final_balance',
        sortable: true,
        formatter: val => `KES ${Number(val).toLocaleString()}` },
    {
      key: 'created_at',
      sortable: true,
      label: "Transaction Date",
    },
  ]
  const perPage = ref(10)
  const totalPlayerTransactions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const typeFilter = ref(null)
  const isBusy = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refPlayerTransactionListTable.value ? refPlayerTransactionListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPlayerTransactions.value,
    }
  })

  const refetchData = () => {
    refPlayerTransactionListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, typeFilter], () => {
    refetchData()
  })

  const fetchPlayerTransactions = (ctx, callback) => {
    isBusy.value = true
    store
      .dispatch('players/fetchTransactions', {
        search: searchQuery.value,
        wallet_id: playerData.wallet_id,
        page_size: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        type: typeFilter.value,
      })
      .then(response => {
        const { data, paginator } = response.data

        callback(data)
        totalPlayerTransactions.value = paginator.length
        isBusy.value = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching playerTransactions' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePlayerTransactionStatusVariantAndIcon = status => {
    if (status === 'dr') return { variant: 'secondary', icon: 'PieChartIcon' }
    if (status === 'cr') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Cancelled') return { variant: 'primary', icon: 'XIcon' }
    if (status === 'Lost') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }
  const resolvePlayerTransactionAvatarVariant = status => {
    if (status === 'dr') return 'secondary'
    if (status === 'cr') return 'success'
    if (status === 'Cancelled') return 'primary'
    if (status === 'Lost') return 'danger'
    return 'primary'
  }

  return {
    fetchPlayerTransactions,
    tableColumns,
    perPage,
    currentPage,
    totalPlayerTransactions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPlayerTransactionListTable,
    isBusy,

    typeFilter,

    resolvePlayerTransactionStatusVariantAndIcon,
    resolvePlayerTransactionAvatarVariant,
    refetchData,
  }
}
