import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPlayers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('users/', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchTransactions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('wallets/transactions/', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchPlayer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPlayer(ctx, playerData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users/', playerData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPlayer(ctx, playerData) {
      const { id } = playerData
      return new Promise((resolve, reject) => {
        axios
          .patch(`/users/${id}/`, playerData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
