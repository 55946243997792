<template>
  <div>
    <b-card
    no-body
    class="mb-50"
    >
    <div class="m-1">
  
        <!-- Table Top -->
        <b-row>
  
          <!-- Per Page -->
          <!-- <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col> -->
  
          <!-- Search -->
          <b-col
            cols="12"
            md="12"
          >
          <div class="d-flex align-items-end justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search by Subject or Transaction Details"
              />
              <v-select
                v-model="typeFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                class="playerTransactions-filter-select"
                :reduce="val => val.value"
               @input="(val) => $emit('update:statusFilter', val)"
                placeholder="Filter by Type"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
  
        </b-row>
  
      </div>
    </b-card>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <b-table
        ref="refPlayerTransactionListTable"
        :items="fetchPlayerTransactions"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :busy.sync="isBusy"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
      <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
            label="Loading..."
            class="align-middle">
            </b-spinner>
            <strong class=" ml-2">Fetching...</strong>
          </div>
        </template>
        <template #cell(type)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
               :id="`playerTransaction-row-${data.item.id}`"
                size="32"
                :text="avatarText(data.item.type)"
                :variant="`light-${resolvePlayerTransactionAvatarVariant(data.item.type)}`"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.type=='cr' ? 'CR': 'DR' }}
            </span>
          </b-media>
        </template>
        <template #cell(amount)="data">
          <span class="text-nowrap">
            {{ Number(data.item.amount).toLocaleString() }}
          </span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
  
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
  
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPlayerTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
  
          </b-col>
  
        </b-row>
      </div>
    </b-card>
  </div>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BButton,
    BPagination, BDropdown, BDropdownItem, BSpinner, BBadge,
  } from 'bootstrap-vue'
  import { avatarText } from '@core/utils/filter'
  import vSelect from 'vue-select'
  import { onUnmounted } from '@vue/composition-api'
  import store from '@/store'
  import usePlayerTransactionsList from './usePlayerTransactionsList'
  
  import playerStoreModule from '../playerStoreModule'
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BTable,
      BMedia,
      BAvatar,
      BPagination,
      BDropdown,
      BDropdownItem,
      BSpinner,
      BBadge,
      BButton,
      vSelect,
    },
    props: {
      playerData: {
        type: Object,
        required: false,
      },
    },
    setup(props) {
      const PLAYERS_APP_STORE_MODULE_NAME = 'players'
  
      // Register module
      if (!store.hasModule(PLAYERS_APP_STORE_MODULE_NAME)) store.registerModule(PLAYERS_APP_STORE_MODULE_NAME, playerStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(PLAYERS_APP_STORE_MODULE_NAME)) store.unregisterModule(PLAYERS_APP_STORE_MODULE_NAME)
      })
      const typeOptions = [
        { label: 'Credit', value: 'cr' },
        { label: 'Debit', value: 'dr' },
      ]
  
      const {
        fetchPlayerTransactions,
        tableColumns,
        perPage,
        currentPage,
        totalPlayerTransactions,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPlayerTransactionListTable,
  
        typeFilter,
        isBusy,
  
        refetchData,
  
        resolvePlayerTransactionStatusVariantAndIcon,
        resolvePlayerTransactionAvatarVariant,
      } = usePlayerTransactionsList(props)
  
      return {
        fetchPlayerTransactions,
        tableColumns,
        perPage,
        currentPage,
        totalPlayerTransactions,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPlayerTransactionListTable,
        isBusy,
  
        typeFilter,
        typeOptions,
  
        refetchData,
        avatarText,
        resolvePlayerTransactionStatusVariantAndIcon,
        resolvePlayerTransactionAvatarVariant,
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  /* Busy table styling */
  table.b-table[aria-busy='true'] {
    opacity: 0.6;
  }
  
  .playerTransactions-filter-select {
    min-width: 190px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  